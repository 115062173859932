import { IMaxBrainEnvironment } from 'app/projects/shared/src/lib/interfaces/environment.interface';
import { EnvServer } from './enums/env-server.enum';

export const environment: IMaxBrainEnvironment = {
    production: true,
    hmr: false,
    apiUrl: 'https://api.dev.maxbrain.io',
    tinymceUrl: 'https://api.dev.maxbrain.io',
    baseUrlSecondPart: 'dev.maxbrain.io',
    classtimeBaseUrl: 'https://beta.classtime.com',
    classtimeJsUrl: 'https://beta.classtime.com/release/Classtime.js',
    respeakIntegrationUrl: 'https://expert.respeak.io/widget/respeak-widget.umd.cjs',
    unsplashViewsBaseUrl: 'https://views.unsplash.com/v?app_id=mJyTgSjUq3cy1b16fS37zLjjwbaorhYg&photo_id=',
    version: require('../../package.json').version + '-dev',
    server: EnvServer.Dev,
    PSPDFKitLicenseKey:
        // tslint:disable-next-line:max-line-length
        'ThXVLeWK54R25AWEMb6fy22vFyRjGlynsu2jH40R931hcnzOdsqTXZw4SuQgJ-4C8DHRL_SEyXrkC7Xcc9vboz0wWfc89inKoZbiJJWHcLFVpqh6tpsyqkj4Fixfy7uOQVkWP8FiXB-Igxlc9Er5-5Na_n-eKGewBamkHQhbYElNudEph2AGw-BXShntSFk85OpCQ79MDZ6n64qY27GbCiRzBmYooTZlnpV7kSFzucT-4Oa8dQOYSZz0LuWJEbc2nIfSiwK4StbVtRrBQp6j4h4HfECx6wR3D7neQuWnWo0_So-BkdYZFOfDsM8duYF9uBpwzyX_bW74bfwmBKny422CPYM3BQ-3dTD6NP70M6aKGa00564afqdXeEqGlzjvuYuiVXczzxEOcPpcBwTbCAQWaKCVpOav0r2W3-hSEjDRXkd5Jz_2l6cz80aVVEsF6GKC3DMlvXmUfCudS2BJVIc5M7MvEGXVhh8jTj62vK4d9rCG8kiZi4Njy-iZleuWMahTthKQfrmlH2C7UmrRS0PTkGHLg1fGPTHqNNVTE3An35btiQL2rSvWOrsBsydc',
};
